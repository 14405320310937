#distribution {
    >*:nth-of-type(2n) {
        text-align: right;
    }
}

@include media('<=menuXS') {
    #distribution {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        >*:nth-of-type(2n) {
            text-align: left;
        }

        section {
            flex-direction: row;
        }
    }
}