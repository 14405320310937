.logoSettings {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    z-index: 1000;
    color: black;

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100% !important;
        
        h2 {
            padding: 0 1em;
        };

        span {
            padding: 0 3em;
        };
    }
}