#contact {

        section {
                &:has(#contact-message) {
                        display: flex;
                        justify-content: flex-end;
                }
        }

        #contact-message {
                display: flex;
                justify-content: space-between;
                background-color: black;
                border-radius: $border-radius 0 0 $border-radius;
                margin-right: -5em;
                width: 60%;

                >div {
                        flex: 1;
                        margin: 2em;
                        padding: 0 2em;
                        align-content: center;

                        form {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                height: 100%;

                                .reqForm::after {
                                        content: " *";
                                        color: red;
                                }

                                input,
                                textarea {
                                        margin: 1em 0;
                                }

                                label,
                                input,
                                textarea {
                                        width: 100%;
                                        border-radius: $mobile-border-radius;

                                }

                                textarea {
                                        resize: none;
                                        height: 10em;
                                }
                        }

                        &:nth-child(2) {
                                border-left: 1px solid $color;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                        }
                }


                #successMessage {
                        display: flex;
                        text-align: left;

                        -webkit-animation: bounce-in-top 1.1s both;
                        animation: bounce-in-top 1.1s both;

                }


                /* ----------------------------------------------
        * Generated by Animista on 2024-10-16 9:14:58
        * Licensed under FreeBSD License.
        * See http://animista.net/license for more info. 
        * w: http://animista.net, t: @cssanimista
        * ---------------------------------------------- */

                /**
        * ----------------------------------------
        * animation bounce-in-top
        * ----------------------------------------
        */
                @-webkit-keyframes bounce-in-top {
                        0% {
                                -webkit-transform: translateY(-500px);
                                transform: translateY(-500px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                                opacity: 0;
                        }

                        38% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                                opacity: 1;
                        }

                        55% {
                                -webkit-transform: translateY(-65px);
                                transform: translateY(-65px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        72% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }

                        81% {
                                -webkit-transform: translateY(-28px);
                                transform: translateY(-28px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        90% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }

                        95% {
                                -webkit-transform: translateY(-8px);
                                transform: translateY(-8px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        100% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }
                }

                @keyframes bounce-in-top {
                        0% {
                                -webkit-transform: translateY(-500px);
                                transform: translateY(-500px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                                opacity: 0;
                        }

                        38% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                                opacity: 1;
                        }

                        55% {
                                -webkit-transform: translateY(-65px);
                                transform: translateY(-65px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        72% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }

                        81% {
                                -webkit-transform: translateY(-28px);
                                transform: translateY(-28px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        90% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }

                        95% {
                                -webkit-transform: translateY(-8px);
                                transform: translateY(-8px);
                                -webkit-animation-timing-function: ease-in;
                                animation-timing-function: ease-in;
                        }

                        100% {
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                                -webkit-animation-timing-function: ease-out;
                                animation-timing-function: ease-out;
                        }
                }


        }
}

//-----------
// Mobile
//-----------

@include media('<=menuXS') {
        #contact {
                section {
                        &:has(#contact-message) {
                                display: flex;
                                justify-content: flex-start;
                                flex-direction: column;
                        }
                }

                #contact-message {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background-color: rgb(0, 0, 0);
                        border-radius: $border-radius 0 0 $border-radius;
                        margin-right: -5em;
                        width: 105%;

                }
        }
}

//-------
// Tablet
//-------
@include media('>tablet', '<=Ldesktop') {
        #contact {
                #contact-message {
                        width: 120%;
                }
        }
}

//-------
// Big screen
//-------
@include media('>=Ldesktop', '<1600px') {
        #contact {
                #contact-message {
                        width: 80%;
                }
        }
}

//
// Smaller Tablet
//

@include media('<=tablet', '>menuXS') {
        #contact {
                section {
                        &:has(#contact-message) {
                                display: flex;
                                justify-content: flex-start;
                                flex-direction: column;
                        }
                }

                #contact-message {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background-color: rgb(0, 0, 0);
                        border-radius: $border-radius 0 0 $border-radius;
                        margin-right: -5em;
                        width: 105%;

                }
        }
}