
$color: rgb(235, 235, 235);
$sec-color: rgb(151, 151, 151);
$bg-color: rgb(15, 15, 15);
$sec-bg-color: rgb(0, 0, 0);
$shading: rgba(15, 15, 15, 0.69);
$border-radius: 5em;
$mobile-border-radius: 2em;

$desktop-size-p: 1.5em;
$desktop-size-h1: 3em;

$mobile-size-p: 1.25em;
$mobile-size-h1: 2em;