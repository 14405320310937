$dot-size: 12px;    // Default dot size
$dot-active-size: 16px; // Active dot size
$dot-offset: 6px;   // Dot offset from center
$dot-radius: 50%;   // Dot border radius

// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
#carousel-content {
    height: calc(100vh - 100px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    #carousel {
        position: relative;
        width: 80%;
        height: 80%;
        margin: auto;

        #carousel-slides {
            height: 100%;
            transition: opacity 1s;

            .carousel-slide {
                width: 100%;
                height: 100%;
                position: absolute;
                transition: opacity 2s;

                .carousel-slide-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 5em;
                    overflow: hidden;
                    box-shadow: inset 0 -150px 200px $bg-color;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: $border-radius;
                        object-fit: cover;
                        position: relative;
                        transition: top 2s ease-in-out;
                        pointer-events: none;
                        z-index: -1;
                    }
                }

                .carousel-slide-header {
                    position: absolute;
                    bottom: 0;
                    left: -5%;
                    font-size: 6em;
                    z-index: 1;
                    height: 35%;
                    width: 90%;
                    display: flex;
                    align-items: flex-end;

                    h1 {
                        margin: 0;
                        position: relative;
                        font-size: 1em;
                        color: $color;
                        transition: top 2s ease-in-out;
                        text-transform: capitalize;
                    }
                }

                .carousel-slide-location {
                    position: absolute;
                    bottom: 10%;
                    left: 100%;
                    width: 10%;
                    height: 20%;
                    padding-left: 1em;

                    div {
                        margin: 0;
                        position: relative;
                        transition: top 2s ease-in-out;

                        h2 {
                            text-transform: uppercase;
                            text-align: left;
                            font-size: 1em;
                            font-weight: bolder;
                            color: $color;
                        }

                        p {
                            font-weight: lighter;
                            font-size: 1em;
                            color: $color;
                        }
                    }
                }
            }

            .carousel-slide-hidden {
                opacity: 0;
                transition: opacity 2s;
                pointer-events: none;
            }

            .carousel-slide-element-above {
                top: -130%;
                transition: top 2s ease;
            }

            .carousel-slide-element-center {
                top: 0;
                transition: top 2s ease;
            }

            .carousel-slide-element-under {
                top: 130%;
                transition: top 2s ease;
            }
        }

        #carousel-nav {
            position: absolute;
            width: 200px;
            height: 200px;
            background-color: $bg-color;
            border-radius: 50%;
            top: calc(50% - 100px);
            right: -20px;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;

            #carousel-dots {
                width: 100%;
                height: 100%;
                position: relative;

                .dot {
                    height: $dot-size;
                    width: $dot-size;
                    border-radius: $dot-radius;
                    background-color: $sec-color;
                    position: absolute;
                    top: calc(50% - $dot-size / 2);
                    left: calc(50% - $dot-size / 2);
                    transition: background-color 0.3s, width 0.3s, height 0.3s;
                }

                .dot-active {
                    height: $dot-active-size;
                    width: $dot-active-size;
                    background-color: $color;
                    top: calc(50% - $dot-active-size / 2);
                    left: calc(50% - $dot-active-size / 2);
                }
            }

            #carousel-nav-arrow {
                position: absolute;
                top: 0;
                left: 0;
                padding: 1em;
                pointer-events: none;
                transition: transform 1s;
                transform: rotate(-90deg);
            }
        }
    }
}


// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=tablet') {
    #carousel-content {
    
        #carousel {
            position: relative;
            height: 90%;
            width: 90%;
    
            #carousel-slides {
                transition: opacity 1s;
               
                .carousel-slide {
                    width: 100%;
                    height: 85%;
                    position: absolute;
                    
                    
                    transition: opacity 2s;
    
                    .carousel-slide-image {
                       
    
                        img {
                            
                        }
                    }
    
                    .carousel-slide-header {
                        
                        font-size: 1.5em;
                        max-width: 75%;
    
                        h1 {
                            margin: 0;
                            position: relative;
                            
                            transition: top 2s ease-in-out 0s;
                        }
                    }
    
                    .carousel-slide-location {
                       
                        bottom: auto;
                        top: 100%;
                        right: auto;
                        left: 0;
                        width: 100%;
    
                        div {
                            margin: 0;
                            position: relative;
                            
                            transition: top 2s ease-in-out 0s;
                        }
                    }
                }
    
    
                .carousel-slide-hidden {
                    
                }
    
                .carousel-slide-element-above {
                    
                }
    
                .carousel-slide-element-center {
                   
                }
    
                .carousel-slide-element-under {
                   
                }
            }
    
            #carousel-nav {
                
                width: 100px;
                height: 100px;
               
                top: auto;
                bottom: 15%;
                right: 0;
    
                #carousel-dots {
                    .dot {
                        height: 4px;
                        width: 4px;
                        position: absolute;
                        top: calc(50% - 2px);
                        left: calc(50% - 2px);
                       
                    }

                    .dot-active {
                        height: 6px;
                        width: 6px;
                        background-color: $color;
                        top: calc(50% - 3px);
                        left: calc(50% - 3px);
                    }
                    
                }
    
                #carousel-nav-arrow {
                    
                }
            }
        }
    }
}