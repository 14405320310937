// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
#project {
    #project-header {
        display: flex;
        flex-direction: row;
        justify-content: end;

        >div {
            width: 50%;
        }

        h1 {
            border-bottom: 1px solid $color;
        }

        #project-subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            >* {
                max-width: 45%;
            }


            p {
                font-size: 1em;
            }
        }
    }

    #project-sections {
        section {
            >div {
                &:has(img) {
                    padding: 2em;
                }
            }

            .project-synopsis {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    #project-video-sections {
        section {
            &:has(video) {
                padding: 2em;
                width: 60%;
                margin: auto;
            }
        }
    }
}

// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=tablet') {

    #project {
        #project-header {

            >div {
                width: 100%;
            }

            h1 {
                border-bottom: 1px solid $color;
            }

            #project-subtitle {

                flex-direction: column;
                justify-content: space-between;

                >* {
                    max-width: 100%;
                }
            }
        }

        #project-sections {
            section {
                >div {
                    justify-content: flex-start;
                    width: 100%;

                    img {
                        width: 75vw;
                        height: 100%;
                        object-fit: cover;
                    }

                }

            }

            section:nth-of-type(1) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        #project-video-sections {
            section {
                &:has(video) {
                    width: 100%;
                }
            }
        }
    }
}