// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
#projects {
    .projects-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;

            input,
            select {
                margin: 1em;
            }

        }

        .select-type,
        .select-services {
            position: relative;


            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 2em;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #fdfdfd;
                /* Arrow color */
                pointer-events: none;
                /* Prevent the arrow from interfering with clicks */
            }
        }


    }
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    >a {
        grid-column: auto;
        grid-row: auto;
        padding: 2em;

        div {
            img {
                border-radius: $mobile-border-radius;
                height: 15em;
            }

            h3 {
                margin: 0;
            }

            p {
                margin: 0;
            }
        }
    }
}



// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=menuXS') {
    #projects {
        .projects-filter {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin-top: 0 !important;
            }

            div {
                width: 100%;
                flex-direction: column;

                input,
                select {
                    margin: 0.5em 0;
                    width: 100%;

                }

                .selectFields {
                    flex-direction: row;
                }

            }

            .select-type,
            .select-services {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 1em;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #fdfdfd;
                    /* Arrow color */
                    pointer-events: none;
                    /* Prevent the arrow from interfering with clicks */
                }
            }

            .select-type {
                margin-right: 0.2em;
            }

            .select-services {
                margin-left: 0.2em;
            }
        }

        .projects-grid {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            >a {


                div {
                    img {
                        height: 12em;
                    }
                }
            }
        }
    }
}

// ----------------------------------------------------------------
// Smaller Tablet
// ----------------------------------------------------------------

@include media('>menuXS', '<=Mdesktop') {
    #projects {
        .projects-filter {
            flex-direction: column;
            align-items: flex-start;

            div {
                width: 100%;
                flex-direction: row;

                select {
                    margin: 0.5em 0.2;
                    width: 100%;

                }

                .selectFields {
                    flex-direction: row;
                }

                input {
                    margin: 0.5em 0.2;
                    width: 50%;
                }
            }

            .select-type,
            .select-services {
                position: relative;
                margin: 0 0.2em;
            }

            .select-services::after,
            .select-type::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 1.5em;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #fdfdfd;
                /* Arrow color */
                pointer-events: none;
                /* Prevent the arrow from interfering with clicks */
            }

        }

        .projects-grid {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            >a {


                div {
                    img {
                        height: 12em;
                    }
                }
            }
        }
    }
}