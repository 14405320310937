@import '../_utils.scss';

// ----------------------------------------------------------------
// Desktop
// ----------------------------------------------------------------
@include media ('>tablet') {

    header {
        height: 100px;
    }

    #menu {
        display: flex;
        align-items: flex-end; // Align items to center for better vertical alignment
        align-items: center;
        height: 100%;

        #menu-button {
            display: none;
        }

        #menu-lang-and-leading{
            width: 100%;
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            #leading {
                flex: 1;
            }
            #menu-lang-tablet{
                //padding: 0.5em;
                display: none;
            }
        }
        

        #trailing {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            nav {
                ul {
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        a {
                            text-decoration: none;
                            color: $color;
                            font-size: 1.125em;
                            padding: 0.5em 1.5em;
                            text-wrap: nowrap;
                        }
                    }
                }
            }
        }

        #menu-logo {
            height: 50px;
        }

        #menu-lang {
            display: flex;
            padding: 0;
            
        }
    }

    .menu-selected {
        font-weight: bold;
    }


}

// ----------------------------------------------------------------
// Mobile
// ----------------------------------------------------------------
@include media('<=menuXS') {

    header {
        height: 70px;

    }

    #menu {
        display: flex;
        align-items: center;
        padding-top: 1em;

        #menu-lang-and-leading{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            #menu-lang-tablet{
                padding: 0.5em;
                display: flex;
            }
        }

        #menu-items {
            display: flex;
            position: fixed;

            left: 0;
            width: 100%;
            z-index: 12;

            background-color: $color;
            border-radius: 0 0 8px 8px;

            &.idle {
                top: calc(-100% + 70px);
                transition: visibility 1ms 400ms, top 400ms ease-in, background-color 400ms, color 400ms;

            }

            &.active {
                top: 70px;
                transition: top 400ms ease-out, background-color 400ms, color 400ms;
            }

            & ul {
                margin: 0;
                padding: 0;
                width: 100%;

                li {
                    margin: 1em;
                    text-align: center;


                    a {
                        color: $bg-color;
                        font-size: 1.5em;
                    }
                }
            }
        }

        #menu-button {
            position: relative;
            width: 50px;
            height: 50px;
            z-index: 11;

            #menu-checkbox {
                position: absolute;
                width: 50px;
                height: 50px;
                margin: 0;
                z-index: 2;
                opacity: 0;
                left: 0;
                top: 0;

                cursor: pointer;
            }

            #menu-checkbox:checked+div>span {
                background-color: transparent;

                &::before {
                    top: 0;
                    rotate: 45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }

                &::after {
                    top: 0;
                    rotate: -45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }
            }

            div {
                position: absolute;

                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    width: 100%;
                    height: 4px;
                    transition: background-color 250ms;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 3px;
                        border-radius: 3px;
                        background-color: $color;
                        transition: top 250ms 125ms, rotate 125ms;
                    }

                    &::before {
                        top: -4px;
                    }

                    &::after {
                        top: 4px;
                    }
                }
            }
        }



        #trailing {
            margin-left: auto;
        }

        #menu-lang {
            padding: 0.5em;
            display: none;
        }

        #menu-logo {
            height: 50px;
        }
    }

    .menu-selected {
        -webkit-text-stroke: 1px $bg-color;
        //color: $secondaryLight !important;
        //border-bottom: 3px solid $secondaryLight !important;
    }

    #menu-shade {
        display: block;
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;

        transition: background-color 400ms ease-in-out;

        &.active {
            background-color: $shading;
        }
    }
}

// ----------------------------------------------------------------
// Tablet
// ----------------------------------------------------------------

@include media('>menuXS', '<=Mdesktop') {
    header {
        height: 70px;
    }


    #menu {
        display: flex;
        flex-direction: column;
        //align-items: center;
        padding-top: 1em;
        margin-bottom: 100px;
        justify-content: space-between;

        #menu-lang-and-leading{
            width: 100%;
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            #menu-lang-tablet{
                padding: 0.5em;
                display: flex;
            }
        }
        
            
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
            
        
        #menu-items {
            display: flex;
            left: 0;
            z-index: 12;

            border-radius: 0 0 8px 8px;

            &.idle {
                top: calc(-100% + 70px);
                transition: visibility 1ms 400ms, top 400ms ease-in, background-color 400ms, color 400ms;

            }

            &.active {
                top: 80px;
                transition: top 400ms ease-out, background-color 400ms, color 400ms;
            }

            & ul {
                margin: 0;
                padding: 0;
                width: 100%;

                li {
                    margin: 1em;
                    text-align: center;


                    a {
                        color: $bg-color;
                        font-size: 1.0em !important;
                    }
                }
            }
        }

        #menu-button {
            position: relative;
            width: 50px;
            height: 50px;
            z-index: 11;

            #menu-checkbox {
                position: absolute;
                width: 50px;
                height: 50px;
                margin: 0;
                z-index: 2;
                opacity: 0;
                left: 0;
                top: 0;

                cursor: pointer;
            }

            #menu-checkbox:checked+div>span {
                background-color: transparent;

                &::before {
                    top: 0;
                    rotate: 45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }

                &::after {
                    top: 0;
                    rotate: -45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }
            }

            div {
                position: absolute;

                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    width: 100%;
                    height: 4px;
                    transition: background-color 250ms;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 3px;
                        border-radius: 3px;
                        background-color: $color;
                        transition: top 250ms 125ms, rotate 125ms;
                    }

                    &::before {
                        top: -4px;
                    }

                    &::after {
                        top: 4px;
                    }
                }
            }
        }



        #trailing {
            margin-left: 5em;
            margin-right: 5em;
            display: flex;
            justify-content: flex-start !important;
        }

        #menu-lang {
            padding: 0.5em;
            display: none;
        }

        #menu-logo {
            height: 50px;
        }
    }

    #menu-shade {
        display: block;
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;

        transition: background-color 400ms ease-in-out;

        &.active {
            background-color: $shading;
        }
    }
}

//
// Smaller Tablet
//
@include media('<=tablet', '>menuXS') {

    #menu {
        display: flex;
        align-items: center;
        padding-top: 1em;
        flex-direction: row;

        #menu-lang-and-leading{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            #menu-lang-tablet{
                padding: 0.5em;
                display: flex;
            }
        }

        #menu-items {
            display: flex;
            position: fixed;

            left: 0;
            width: 100%;
            z-index: 12;

            background-color: $color;
            border-radius: 0 0 8px 8px;

            &.idle {
                top: calc(-100% + 70px);
                transition: visibility 1ms 400ms, top 400ms ease-in, background-color 400ms, color 400ms;

            }

            &.active {
                top: 70px;
                transition: top 400ms ease-out, background-color 400ms, color 400ms;
            }

            & ul {
                margin: 0;
                padding: 0;
                width: 100%;

                li {
                    margin: 1em;
                    text-align: center;


                    a {
                        color: $bg-color;
                        font-size: 1.5em;
                    }
                }
            }
        }

        #menu-button {
            position: relative;
            width: 50px;
            height: 50px;
            z-index: 11;

            #menu-checkbox {
                position: absolute;
                width: 50px;
                height: 50px;
                margin: 0;
                z-index: 2;
                opacity: 0;
                left: 0;
                top: 0;

                cursor: pointer;
            }

            #menu-checkbox:checked+div>span {
                background-color: transparent;

                &::before {
                    top: 0;
                    rotate: 45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }

                &::after {
                    top: 0;
                    rotate: -45deg;
                    transition: top 250ms, rotate 125ms 125ms;
                }
            }

            div {
                position: absolute;

                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    width: 100%;
                    height: 4px;
                    transition: background-color 250ms;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 3px;
                        border-radius: 3px;
                        background-color: $color;
                        transition: top 250ms 125ms, rotate 125ms;
                    }

                    &::before {
                        top: -4px;
                    }

                    &::after {
                        top: 4px;
                    }
                }
            }

            #leading {
                margin-bottom: 50px;
            }
        }



        #trailing {
            margin-left: auto;
        }

        #menu-lang {
            padding: 0.5em;
            display: none;
        }

        #menu-logo {
            height: 50px;
        }
    }

    .menu-selected {
        -webkit-text-stroke: 1px $bg-color;
        //color: $secondaryLight !important;
        //border-bottom: 3px solid $secondaryLight !important;
    }

    #menu-shade {
        display: block;
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;

        transition: background-color 400ms ease-in-out;

        &.active {
            background-color: $shading;
        }
    }
}