.cursor {
    position: fixed;
    width: 1em;
    height: 1em;
    border-radius: 100px;
    background-color: $color;
    transform: translate(-50%, -50%);
    pointer-events: none;
    mix-blend-mode: difference;
    z-index: 100;
    transition: width .3s, height .3s;
}

.cursor-pointer {
    width: 4em;
    height: 4em;
}

.cursor-touch {
    display: none;
}